import React from 'react';
import Title from '../../components/title';
import Customer from '../../components/customer';

const Index = () => {
  return (
    <div>
      <Title type="side" text="客户案例" />
      <Customer />
    </div>
  );
};

export default Index;
