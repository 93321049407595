import React from "react";
import Title from "../../components/title";
import { PicBoxService } from "../../components/pic-box";

const Index = () => {
  return (
    <div>
      <Title type="side" text="服务范围" />
      <PicBoxService />
    </div>
  );
};

export default Index;
