import './App.css';
import React from 'react';
import Nav from './components/nav';
import Footer from './components/footer';
import RouterIndex from './router';
import { BrowserRouter as Router } from 'react-router-dom';

function App() {
  return (
    <div className="App app-mobile">
      <Router>
        <Nav />
        <div className="layout">
          <RouterIndex />
        </div>
        <Footer />
      </Router>
    </div>
  );
}
export default App;
