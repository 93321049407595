import React from "react";
import "./index.css";
import PoliceImg from "../../assets/police.png";

const Footer = () => {
  return (
    <div className="footer">
      <div className="copy">
        <div>
          @2009~2021 上海清嘉实业有限公司 <i>沪ICP备11046503号-2</i>
        </div>
        <div>
          <i className="police">
            <a
              target="_blank"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011602002112"
            >
              <img src={PoliceImg} />
              沪公网安备 31011602002112号
            </a>
          </i>
        </div>
      </div>
    </div>
  );
};

export default Footer;
