import React, { useState } from 'react';
import { Menu, NavBar } from 'antd-mobile';
import { withRouter } from 'react-router-dom';
import MenuIconImg from '../../assets/icon/menu.svg';
import CloseIconImg from '../../assets/icon/close.svg';
import LogoImg from '../../assets/logo.png';
import './index.css';

const initData = [
  {
    value: 'home',
    label: '首页',
  },
  {
    value: 'service',
    label: '服务范围',
  },
  {
    value: 'intro',
    label: '企业介绍',
  },
  {
    value: 'customer',
    label: '客户案例',
  },
  {
    value: 'contact',
    label: '联系方式',
  },
];

const Nav = ({ location, history }) => {
  const [showMenu, setShowMenu] = useState(false);
  const { pathname } = location;
  const pathKey = pathname.replace('/', '') || 'home';

  // 点击右上角图标控制菜单显隐
  const onMenuIconClick = (e) => {
    showMenu ? setShowMenu(false) : setShowMenu(true);
  };

  const onMaskClick = (e) => {
    setShowMenu(false);
  };

  const onMenuChange = (e) => {
    const key = e && e[0];
    setShowMenu(false);
    pathKey !== key && history.push(key);
  };

  const menuIcon = showMenu ? CloseIconImg : MenuIconImg;

  const menuEl = (
    <Menu
      className="menu"
      data={initData}
      value={['1']}
      level={1}
      onChange={onMenuChange}
      height={document.documentElement.clientHeight * 0.6}
    />
  );

  return (
    <>
      <NavBar
        mode="light"
        rightContent={
          <i className="icon-wrap" onClick={onMenuIconClick}>
            <img src={menuIcon} className="am-icon menu-icon" alt="菜单" />
          </i>
        }
        className="nav"
      >
        <img className="logo" src={LogoImg} alt="logo" />
      </NavBar>
      {showMenu ? menuEl : null}
      {showMenu ? <div className="menu-mask" onClick={onMaskClick} /> : null}
    </>
  );
};

export default withRouter(Nav);
