import React from 'react';
import { Accordion } from 'antd-mobile';
import BankList from './bank';
import HotelList from './hotel';
import ArchList from './arch';
import TechList from './tech';
import ShopList from './shop';
import BankImg from '../../assets/icon/bank.svg';
import HotelImg from '../../assets/icon/hotel.svg';
import ArchImg from '../../assets/icon/arch.svg';
import TechImg from '../../assets/icon/tel.svg';
import CompanyImg from '../../assets/icon/company.svg';
import './index.css';

const Customer = () => {
  return (
    <div className="customer">
      <Accordion defaultActiveKey="4" className="accordion-customer">
        <Accordion.Panel
          header={
            <div>
              <img className="icon" src={BankImg} alt="bank" />
              银行/金融
            </div>
          }
        >
          <BankList />
        </Accordion.Panel>
        <Accordion.Panel
          header={
            <div>
              <img className="icon" src={HotelImg} alt="hotel" />
              酒店/服务
            </div>
          }
        >
          <HotelList />
        </Accordion.Panel>
        <Accordion.Panel
          header={
            <div>
              <img className="icon" src={ArchImg} alt="arch" />
              建筑/物流
            </div>
          }
        >
          <ArchList />
        </Accordion.Panel>
        <Accordion.Panel
          header={
            <div>
              <img className="icon" src={TechImg} alt="tech" />
              科技/医药
            </div>
          }
        >
          <TechList />
        </Accordion.Panel>
        <Accordion.Panel
          header={
            <div>
              <img className="icon" src={CompanyImg} alt="company" />
              企业/其他
            </div>
          }
        >
          <ShopList />
        </Accordion.Panel>
      </Accordion>
    </div>
  );
};

export default Customer;
