import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./index.css";

const Title = (props) => {
  const { text, type } = props;
  const cls = classnames("title", type);
  return (
    <h2 className={cls}>
      <span className="text">{text}</span>
    </h2>
  );
};

Title.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
};
export default Title;
