import React from "react";
import Contact from "../../components/contact";
import Title from "../../components/title";

const Index = () => {
  return (
    <div>
      <Title type="side" text="联系方式" />
      <Contact />
    </div>
  );
};

export default Index;
