import React from "react";
import { Carousel } from "antd-mobile";
import Banner_1 from "../../assets/banner/banner_big_1.jpg";
import Banner_2 from "../../assets/banner/banner_big_2.jpg";
import Banner_3 from "../../assets/banner/banner_big_3.jpg";
import Banner_4 from "../../assets/banner/banner_big_4.jpg";
import "./index.css";

const Index = () => {
  return (
    <Carousel className="carousel" autoplay={true} infinite>
      <div>
        <img src={Banner_1} alt="" />
      </div>
      <div>
        <img src={Banner_2} alt="" />
      </div>
      <div>
        <img src={Banner_3} alt="" />
      </div>
      <div>
        <img src={Banner_4} alt="" />
      </div>
    </Carousel>
  );
};

export default Index;
