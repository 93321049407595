import React from "react";
import Title from "../../components/title";
import Intro from "../../components/intro";

const Index = () => {
  return (
    <div>
      <Title type="side" text="企业介绍" />
      <Intro />
    </div>
  );
};

export default Index;
