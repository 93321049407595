import React from "react";
import Pic from "../../pic";
import PicBuilding from "../../../assets/service/building.jpg";
import PicShip from "../../../assets/service/ship.jpg";
import PicExpo from "../../../assets/service/expo.jpg";
import PicHotel from "../../../assets/service/hotel.jpg";
import PicBank from "../../../assets/service/bank.jpg";
import PicComputer from "../../../assets/service/computer.jpg";
import PropTypes from "prop-types";
import "./index.css";

const PicBoxService = (props) => {
  const { style } = props;
  return (
    <div style={style} className="pic-box">
      <ul className="pic-list pic-list-service">
        <li className="item">
          <Pic
            type="slide"
            src={PicBuilding}
            title="工程供电"
            text="满足建筑施工现场，市政建设工地的用电需求，可为桩机等大型设备持续供电。"
          />
        </li>
        <li className="item">
          <Pic
            type="slide"
            src={PicShip}
            title="船舶供电"
            text="为海上运行的船舶提供长期稳定的设备和生活用电。"
          />
        </li>
      </ul>
      <ul className="pic-list">
        <li className="item">
          <Pic
            type="slide"
            src={PicExpo}
            title="展会供电"
            text="博览会、秀场等大型展会的可靠电力供应。"
          />
        </li>
        <li className="item">
          <Pic
            type="slide"
            src={PicComputer}
            title="机房供电"
            text="提供大型机房，数据中心等的备用电力方案，为双11/双12等电商促销季保驾护航。"
          />
        </li>
      </ul>
      <ul className="pic-list">
        <li className="item">
          <Pic
            type="slide"
            src={PicHotel}
            title="酒店供电"
            text="满足酒店和其他行业应急发电的需求，让您不再为临时停电而苦恼。"
          />
        </li>
        <li className="item">
          <Pic
            type="slide"
            src={PicBank}
            title="其他供电"
            text="承接各类企业应急和长期发电需求，如银行、医院、办公园区等。"
          />
        </li>
      </ul>
    </div>
  );
};

PicBoxService.propTypes = {
  style: PropTypes.object,
};

export default PicBoxService;
